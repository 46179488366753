<template>
  <v-row :dense="$vuetify.breakpoint.smAndDown" class="justify-center mb-5" :style="$vuetify.breakpoint.smAndDown ? 'width: 100vw;' : ''">
    <v-col cols="12" xl="9" lg="10">
      <v-card rounded color="card" style="position: relative">
        <v-btn @click="fontSize++" style="position: fixed; top: 100px; right: 170px;" color="accent" fab small><v-icon>mdi-plus</v-icon></v-btn>
        <v-btn @click="fontSize--" style="position: fixed; top: 100px; right: 120px;" color="accent" fab small><v-icon>mdi-minus</v-icon></v-btn>
        <v-card-title><h1>Manual LaTeX</h1></v-card-title>
        <v-card-text class="text--primary text-justify pa-8" :style="`font-size: ${fontSize}px`">
          <h2>A Motivação:</h2>
          <p>
            Ao escrever enunciados de prova, encontramos um desafio:<br>
            Como representar expressões matemáticas, como <span class="tex2jax_process">$\boxed{\displaystyle{E=\frac{mv^2}{2}}}$</span>?
          </p>

          <h2>A Solução:</h2>
          <p>
            O código TeX, dentre várias vantagens, permite escrever essas expressões de modo estruturado.<br>
            Assim, é a <b>escolha ideal</b> para este trabalho!
          </p>

          <h2>Primeiros passos:</h2>
          <p>
            O nosso primeiro desafio é: como eu <b>separo as fórmulas</b> do restante do texto?<br><br>
            A solução é criar um <b>separador</b>, que no nosso caso é o cifrão <b>$</b>.
          </p>

          <p>
            Assim, se eu quero representar a frase "Prove que <span class="tex2jax_process">$1+1=2$</span>", basta escrever:<br>
           </p>
          <div class="pa-4 primary lighten-3" style="width: fit-content; border-radius: 2rem;">
            Prove que $1+1=2$ <span class="tex2jax_process">$\Longrightarrow$</span> Prove que <span class="tex2jax_process">$1+1=2$</span>
          </div>
          <br>

          Note que no exemplo acima, a equação ficou na mesma linha que o texto.<br>
          Para os casos em que a fórmula é separada do texto, usamos o cifrão duplo <b>$$</b> como separador. Veja o exemplo:<br><br>

          A equivalência massa-energia é descrita pela famosa equação $$E = mc^2$$ proposta por Albert Einstein em 1905.<br><br>
          <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
            A equivalência massa-energia é descrita pela famosa equação $$E = mc^2$$ proposta por Albert Einstein em 1905.
          </div>

          <h2>Símbolos Especiais:</h2>
          <div class="tex2jax_process">
            Você notou que, no exemplo acima, usamos <b>mc^2</b> para representar o número 2 no expoente?<br>
            Falaremos melhor sobre isso na seção "Subscritos e Sobrescritos"!<br><br>
            Antes disso, vamos resolver o nosso próximo desafio: como representar símbolos matemáticos que não existem no nosso teclado?<br><br>
            Por exemplo, a letra grega Alfa $(\alpha)$ e o símbolo de somatório $\sum$.<br>
            No LaTeX eles são representados por <b>\alpha</b> e <b>\sum</b>, respectivamente.<br><br>

            Repare que todo símbolo especial começa com o símbolo de barra invertida \<br>
            Abaixo, uma tabela com vários símbolos especiais:<br><br>
          </div>

          <v-btn href="https://en.wikipedia.org/wiki/Help:Displaying_a_formula#Formatting_using_TeX" target="_blank" color="primary" depressed class="white--text mr-4">Tabela Completa</v-btn>
          <v-btn href="https://pt.wikipedia.org/wiki/Ajuda:Guia_de_edi%C3%A7%C3%A3o/F%C3%B3rmulas_TeX#Caracteres_especiais" target="_blank" text color="primary">+ Referência Curta</v-btn>
          <br><br>

          Usando os símbolos acima, já temos muitos poderes nas mãos!<br><br>
          Experimente abrir o <v-btn href="/editor-live" target="_blank" color="accent" depressed small class="white--text">Editor Live</v-btn> para interagir com as fórmulas.

          <h2>Expressões importantes:</h2>
          <div class="tex2jax_process">
            Depois de explorar, você pode estar sentindo falta de uma coisa importante:<br>
            Como nós escrevemos frações?<br><br>

            A expressão <b>\frac</b> representa a "barra" da fração.<br>
            E agora, como separamos o numerador do denominador?<br><br>

            Aqui surge os caracteres mais importante de todos: as chaves <b>{ }</b><br>
            São os <b>separadores universais</b> dentro do LaTeX.<br><br>

            Com eles, conseguimos escrever a fração $\dfrac{1}{2}$ assim: \frac{1}{2}<br><br>

            Os binomiais seguem a mesma lógica (lembram das aulas de combinatória)?<br><br>
            \binom{n}{k} $\displaystyle\Longrightarrow\ \binom{n}{k}$<br><br>

            Assim, já conseguimos escrever nossa primeira expressão complexa:<br><br>
            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              \binom{n}{k} = \frac{ n! }{ k! (n-k)! }<br><br>
              $\displaystyle\binom{n}{k} = \frac{ n! }{ k! (n-k)! }$
            </div><br><br>

            Analise com calma cada letra usada na equação acima. É importante que tudo faça sentido para prosseguirmos!<br><br>

            O próximo passo é colocar uma fração dentro da outra:<br>
            $$\dfrac{1000}{ \frac{2}{3} }$$<br><br>

            É apenas: \frac{ 1000 }{ \frac{2}{3} }<br><br>

            Repare que, dentro das chaves <b>{ }</b>, os espaços são opcionais.<br>
            Então tanto faz escrever \frac{ 1 }{ 2 } ou \frac{1}{2}, o resultado continua sendo $\dfrac{1}{2}$<br><br>

            Outros exemplos usando chaves:<br><br>
            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              \begin{align}
              \text{\\sqrt{3}} &\Longrightarrow \sqrt{3}\\\\
              \text{\\text{Duas palavras!}} &\Longrightarrow \text{Duas palavras!}\\\\
              \text{\\sin{ 2\pi }} &\Longrightarrow \sin{ 2\pi }\\\\
              \text{\\vec{F}} &\Longrightarrow \vec{F}\\\\
              \text{\\sqrt[10]{1024}=2} &\Longrightarrow \sqrt[10]{1024}=2
              \end{align}
            </div><br><br>

          </div>

          <h2>Subscritos e Sobrescritos</h2>

          Lembra que no nosso primeiro exemplo $E=mc^2$ usamos o ^2 para colocar o 2 no expoente?<br>
          <b>Todo sobrescrito funciona assim!</b> Basta colocar um acento circunflexo entre um símbolo e outro: criamos um expoente:<br><br>

          <div class="tex2jax_process">
          Mas ao testar com mais de um dígito, surge um problema:<br>
          $$a^12 + b^12$$
          Como eu obrigo o $2$ aparecer no expoente, mas o sinal de + não?<br><br>

          Vamos usar as chaves! Basta fazer a^{12} que somente o 12 vai para o expoente.<br>
          É o mesmo princípio das frações!<br><br>

          <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
            a^{12} + b^{12} $$a^{12} + b^{12}$$
          </div><br><br>

          E não para por aí: além de números, podemos colocar qualquer coisa dentro das chaves. Por exemplo:<br>
          $$144^{ \frac{1}{2} } + \beta^{ 5 }$$
          É apenas 144^{ \frac{1}{2} } + \beta^{ 5 }<br><br>

          Falta agora falar dos subscritos: como escrever $x_1$ e $x_2$ ?<br>
            Basta colocar o símbolo <b>_</b><br><br>

          No exemplo acima: x_1 e x_2 são $x_1$ e $x_2$<br>
          Naturalmente, se quisermos representar $x_{123}$ basta usar as chaves:<br><br>
            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              x_{123} $\Longrightarrow x_{123}$
            </div><br><br>

          Um logaritmo na base 2 fica: \log_2 -> $\log_2$<br><br>

          Exemplo: $\log_{2} 2^{10} = 10$<br>
          \log_{2} 2^{10} = 10<br><br>

          Hora do desafio: como representar o somatório $\sum^{n}_{i=0}$ ?<br><br>
          Vamos por partes: sabemos que o Sigma é <b>\sum</b><br>
          Agora, para colocar o $n$ na parte de cima do sigma, usamos <b>\sum^{n}</b><br>
          E finalmente, para colocar o $i=0$ abaixo do sigma, usamos <b>\sum_{i=0}</b><br><br>

          Combinando os dois:<br><br>
            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              \sum^{n}_{i=0} $\sum^{n}_{i=0}$
            </div><br><br>

          Sempre que precisar, use o <v-btn href="/editor-live" target="_blank" color="accent" depressed small class="white--text">Editor Live</v-btn> para experimentar!<br><br>

          Um outro exemplo que combina tanto sobrescritos quanto subscritos é a permissividade elétrica do vácuo em notação científica:<br><br>

          <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
            \epsilon_0 = 8,85 \times 10^{-12}<br>
            $$\epsilon_0 = 8,85 \times 10^{-12}$$
          </div><br><br>

          Alguns detalhes: reparou no espaço entre os dois números $8$?<br>
          Usamos um par de chaves ao redor dela para ignorar os espaços: 8{,}85 -> $8{,}85$<br><br>

          E mais importante: esquecemos das unidades do $\epsilon_0$ !
          </div>

          <h2>Física e Química</h2>
          <div class="tex2jax_process">
            Para representar unidades, usamos a expressão \pu sigla em inglês para Physical Units).<br><br>

            No exemplo anterior, para representar as unidades $ \pu{F m-1}$ do $\epsilon_0$, basta escrever \pu{F m-1}:<br><br>

            Repare que, dentro do \pu escrever um espaço entre o $F$ e o $m$ fez com que eles se separassem corretamente.<br>
            Melhor ainda: também <b>não foi necessário</b> colocar o acento circunflexo antes do $-1$ para colocá-lo no expoente!<br><br>

            Usar o \pu facilita bastante a representação de unidades.<br>
            As letras também saem do modo itálico, ideal para as unidades:<br><br>

            Comparando m/s^2 com \pu{m/s2}:<br><br>
            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              2,0 m/s^2 $\Longrightarrow$ \pu{2,0 m/s2}
              $$2,0 m/s^2 \Longrightarrow \pu{2,0 m/s2}$$
            </div><br><br>

            E por último, temos as equações químicas: $\ce{Na+ + Cl- -> NaCl}$<br><br>

            Aqui usaremos a expressão \ce (sigla em inglês para chemical equation).<br><br>
            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              \ce{H2O} -> $\ce{H2O}$
            </div><br><br>

            É parecido com o \pu: não precisamos escrever <b>_2</b> para colocar o 2 abaixo do H.<br>
            E olhando para o primeiro exemplo, também não precisamos do <b>^+</b> para colocar o sinal de + no expoente.<br>
            Basta escrever ao lado do $\ce{Na}$:<br><br>

            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              \ce{Na+ + Cl- -> NaCl}<br>
              Produz a equação: $\displaystyle\ce{Na+ + Cl- -> NaCl}$
            </div><br><br>

          </div>

          <v-btn href="https://mhchem.github.io/MathJax-mhchem/" target="_blank" color="accent" depressed
                 class="white--text text-capitalize">Leia o guia completo aqui</v-btn>

          <h2>Conceitos Avançados:</h2>
          <div class="tex2jax_process">
            O nome pode assustar, mas não se preocupe! Com comandos pequenos, conseguimos escrever expressões complexas.<br><br>

            Um problema que você pode ter encontrado é colocar uma fração dentro de parênteses:
            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              (\frac{1}{2})^2 $$(\frac{1}{2})^2$$
            </div><br><br>

            Para garantir que a altura dos parênteses respeite o conteúdo no interior deles, basta colocar <b>\left</b> antes do parêntese da esquerda, e <b>\right</b> antes do parêntese da direita.<br>
            Fica assim:<br><br>

            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              \left( \frac{1}{2} \right)^2
              $$\left( \frac{1}{2} \right)^2$$
            </div><br><br>

            O mesmo vale para colchetes, chaves, etc.<br>
            Lembrando que para representar os símbolos especiais <b>\$</b> e <b>{}</b> nós usamos a barra invertida:<br><br>

            <div class="pa-4 primary lighten-3 tex2jax_process" style="width: fit-content; border-radius: 2rem;">
              \\$ $\Longrightarrow\$$<br>
              \{ $\Longrightarrow\{$<br>
              \} $\Longrightarrow\}$<br>
            </div><br><br>

            E por fim, um guia de como usar os ambientes especiais e um desafio:<br><br>
          </div>

          <div style="position: relative; height: 250px;">
            <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" allowfullscreen
                    src="https://www.youtube.com/embed/-cYimWlGxpQ" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"/>
          </div><br><br>

          Com este guia, é possível escrever todo tipo de expressão matemática com apenas 30-45 min treinando.<br><br>
          <i>Havendo alguma dúvida, entre em contato conosco!</i>

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Manual",
  mounted() { if(window.MathJax) { window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]); } },
  data() { return {
    fontSize: 16
  } }
}
</script>

<style scoped>
 h2 { margin: 2rem 0; }
</style>
